.MenuGroup a {
  text-decoration: none;
  color: black;
  line-height: 28px;
}

.Logo {
  width: 100%;
  height: 100%;
  background-image: url("./images/JH-logo-black.png");
  background-size: cover;
  background-position: center;
}

.LogoMobile {
  width: 40px;
  height: 40px;
  margin-top: 10px;
  background-image: url("./images/JH-logo-white.png");
  background-size: cover;
  background-position: center;
}


#AboutPosition {
  margin-left: 36px;
}

@media only screen and (min-width: 1280px) {
  .MenuWeb {
    display: block;
  }

  .MenuMobile {
    display: none;
  }

  .MenuWrapper {
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
  }

  .Menu {
    width: 1200px;
    height: 100%;
    font-family: 'Overpass';
    font-size: 14px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .MenuGroup {
    width: 180px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .LogoContainer {
    width: 120px;
    height: 121px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
}

@media only screen and (max-width: 1279.9px) {
  .MenuWeb {
    display: block;
  }

  .MenuMobile {
    display: none;
  }

  .MenuWrapper {
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
  }

  .Menu {
    width: calc(100% - 80px);
    height: 100%;
    font-family: 'Overpass';
    font-size: 14px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .MenuGroup {
    width: 180px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .LogoContainer {
    width: 120px;
    height: 121px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
}

@media only screen and (max-width: 1024px) {
  .MenuWeb {
    display: block;
  }

  .MenuMobile {
    display: none;
  }

  .MenuWrapper {
    width: 100%;
    height: 130px;
    display: flex;
    justify-content: center;
  }

  .Menu {
    width: calc(100% - 20px);
    height: 100%;
    font-family: 'Overpass';
    font-size: 13px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .MenuGroup {
    width: calc(50% - 60px);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .LogoContainer {
    width: 100px;
    height: 101px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

@media only screen and (max-width: 460px) {
  .MenuWeb {
    display: none;
  }

  .MenuMobile {
    display: block;
  }
  
  .MenuWrapper {
    width: 100%;
    height: 130px;
    display: flex;
    justify-content: center;
  }

  .Menu {
    width: calc(100% - 20px);
    height: 100%;
    font-family: 'Overpass';
    font-size: 13px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .MenuGroup {
    width: calc(50% - 60px);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .LogoContainer {
    width: 100px;
    height: 101px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
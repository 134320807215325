@keyframes fade {
    0% {
        opacity: 0;
    }
}

.DirectionBar a:hover {
    color: rgb(209, 209, 209);
}

.PPColumn1TextBox h3 {
    margin-bottom: -8px;
}

.PPColumn1TextBox img {
    margin-top: 10px;
    width: 100%;
    height: auto;
}

.PPColumn1TextBox a {
    text-decoration: none;
}

.PPColumn2TextBox {
    width: calc(100% - 40px);
    font-size: 11px;

    display: flex;
    flex-direction: column;
}

.PPColumn2TextBox p {
    margin-top: 0;
}

.PPBar100 {
    position: relative;
    width: 100%;
    height: 5px;
    margin-bottom: 18px;
    background-color: #f8f8f8;
}

.PPBar100:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 5px;
    background-color: rgb(0, 255, 0);
}

.PPBar75 {
    position: relative;
    width: 100%;
    height: 5px;
    margin-bottom: 18px;
    background-color: #f8f8f8;
}

.PPBar75:before {
    position: absolute;
    content: "";
    width: 75%;
    height: 5px;
    background-color: rgb(0, 192, 0);
}

.PPBar50 {
    position: relative;
    width: 100%;
    height: 5px;
    margin-bottom: 18px;
    background-color: #f8f8f8;
}

.PPBar50:before {
    position: absolute;
    content: "";
    width: 50%;
    height: 5px;
    margin-bottom: 18px;
    background-color: rgb(0, 128, 0);
}

.PPLinks a:hover {
    color: rgb(255, 0, 255);
}

.PPImageWrapper h2 {
    margin-top: 40px;
    margin-bottom: 28px;
}

#pCenter {
    text-align: center;
    font-size: 13px;
}

@media only screen and (min-width: 1280px) {
    .PPWrapper {
        width: 100%;
    
        display: flex;
        flex-direction: column;
        align-items: center;
    
        animation: fade 0.6s ease-in;
    }

    .PPTitle {
        width: 1200px;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(209, 209, 209, 0.5);
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .PPContentWrapper {
        width: 100%;
        margin-top: 20px;
    
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .PPColumn1 {
        width: 900px;
    
        display: flex;
        justify-content: flex-end;
    }

    .PPColumn2 {
        width: 300px;
    
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .PPColumn1Content {
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
        margin-right: 10px;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(209, 209, 209, 0.5);

        display: flex;
        justify-content: center;
    }

    .PPColumn1TextBox {
        width: calc(100% - 60px);
    
        font-size: 16px;
        text-align: justify;
    }
    
    .PPColumn2Content {
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
        margin-left: 10px;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(209, 209, 209, 0.5);

        display: flex;
        justify-content: center;
    }

    .PPImageWrapper {
        width: 1200px;
        margin-top: 20px;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(209, 209, 209, 0.5);

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .PPLinks {
        width: 100%;
    
        display: flex;
        flex-direction: column;
    }
    
    .PPLinks a {
        margin-bottom: 15px;
        text-decoration: none;
        color: purple;
        font-size: 13px;
    }

    .PPImageContainer {
        width: calc(100% - 40px);

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .PPImageContainer img {
        width: 60%;
        height: auto;
        border: 1px solid rgb(225, 225, 225);
    
        margin-bottom: 40px;
    }

    .PPTitle h3 {
        margin-top: 30px;
        font-size: 32px;
    }
    
    .PPTitle p {
        margin-top: -30px;
        margin-bottom: 20px;
        color: rgb(175, 175, 175);
        font-size: 15px;
        font-weight: 400;
    }
    
    .DirectionBar {
        width: 220px;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
    }
    
    .DirectionBar a {
        text-decoration: none;
        color: black;
        font-size: 12px;
    }
}

@media only screen and (max-width: 1279.9px) {
    .PPWrapper {
        width: 100%;
    
        display: flex;
        flex-direction: column;
        align-items: center;
    
        animation: fade 0.6s ease-in;
    }

    .PPTitle {
        width: calc(100% - 80px);
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(209, 209, 209, 0.5);
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .PPContentWrapper {
        width: calc(100% - 80px);
        margin-top: 20px;
    
        display: flex;
        flex-wrap: wrap;
    }

    .PPColumn1 {
        width: 75%;
    
        display: flex;
        justify-content: flex-end;
    }

    .PPColumn2 {
        width: 25%;
    
        display: flex;
        justify-content: flex-start;
    }

    .PPColumn1Content {
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
        margin-right: 10px;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(209, 209, 209, 0.5);

        display: flex;
        justify-content: center;
    }

    .PPColumn1TextBox {
        width: calc(100% - 60px);
    
        font-size: 16px;
        text-align: justify;
    }
    
    .PPColumn2Content {
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
        margin-left: 10px;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(209, 209, 209, 0.5);

        display: flex;
        justify-content: center;
    }

    .PPImageWrapper {
        width: 100%;
        margin-top: 20px;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(209, 209, 209, 0.5);

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .PPLinks {
        width: 100%;
    
        display: flex;
        flex-direction: column;
    }
    
    .PPLinks a {
        margin-bottom: 15px;
        text-decoration: none;
        color: purple;
        font-size: 13px;
    }

    .PPImageContainer {
        width: calc(100% - 40px);
        
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .PPImageContainer img {
        width: 696px;
        height: auto;
        border: 1px solid rgb(225, 225, 225);
    
        margin-bottom: 40px;
    }

    .PPTitle h3 {
        margin-top: 30px;
        font-size: 32px;
    }
    
    .PPTitle p {
        margin-top: -30px;
        margin-bottom: 20px;
        color: rgb(175, 175, 175);
        font-size: 15px;
        font-weight: 400;
    }
    
    .DirectionBar {
        width: 220px;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
    }
    
    .DirectionBar a {
        text-decoration: none;
        color: black;
        font-size: 12px;
    }
}

@media only screen and (max-width: 1024px) {
    .PPWrapper {
        width: 100%;
    
        display: flex;
        flex-direction: column;
        align-items: center;
    
        animation: fade 0.6s ease-in;
    }

    .PPTitle {
        width: calc(100% - 40px);
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(209, 209, 209, 0.5);
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .PPContentWrapper {
        width: calc(100% - 40px);
        margin-top: 20px;
    
        display: flex;
        flex-wrap: wrap;
    }

    .PPColumn1 {
        width: 100%;
    
        display: flex;
        justify-content: flex-end;
    }

    .PPColumn2 {
        width: 100%;
    
        display: flex;
        justify-content: flex-start;
    }

    .PPColumn1Content {
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
        margin-right: 0px;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(209, 209, 209, 0.5);

        display: flex;
        justify-content: center;
    }

    .PPColumn1TextBox {
        width: calc(100% - 60px);
    
        font-size: 16px;
        text-align: left;
    }
    
    .PPColumn2Content {
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
        margin-top: 20px;
        margin-left: 0px;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(209, 209, 209, 0.5);

        display: flex;
        justify-content: center;
    }

    .PPColumn2TextBox {
        font-size: 12px !important;
    }

    .PPColumn2TextBox h2 {
        text-align: center;
    }

    .PPColumn2TextBox a {
        margin-bottom: 10px;
        text-decoration: none;
        text-align: center !important;
        color: purple;
        font-size: 13px;
    }

    .PPImageWrapper {
        width: 100%;
        margin-top: 20px;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(209, 209, 209, 0.5);

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .PPLinks {
        width: 100%;
    
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .PPLinks a {
        margin-bottom: 15px;
        text-decoration: none;
        color: purple;
        font-size: 14px;
    }

    .PPImageContainer {
        width: calc(100% - 40px);
        
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .PPImageContainer img {
        width: calc(100% - 40px);
        height: auto;
        border: 1px solid rgb(225, 225, 225);
    
        margin-bottom: 40px;
    }

    .PPTitle h3 {
        margin-top: 30px;
        font-size: 32px;
    }
    
    .PPTitle p {
        margin-top: -30px;
        margin-bottom: 20px;
        color: rgb(175, 175, 175);
        font-size: 15px;
        font-weight: 400;
    }
    
    .DirectionBar {
        width: 220px;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
    }
    
    .DirectionBar a {
        text-decoration: none;
        color: black;
        font-size: 12px;
    }
}

@media only screen and (max-width: 760px) {
    .PPWrapper {
        width: 100%;
    
        display: flex;
        flex-direction: column;
        align-items: center;
    
        animation: fade 0.6s ease-in;
    }

    .PPTitle {
        width: calc(100% - 40px);
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(209, 209, 209, 0.5);
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .PPContentWrapper {
        width: calc(100% - 40px);
        margin-top: 20px;
    
        display: flex;
        flex-wrap: wrap;
    }

    .PPColumn1 {
        width: 100%;
    
        display: flex;
        justify-content: flex-end;
    }

    .PPColumn2 {
        width: 100%;
    
        display: flex;
        justify-content: flex-start;
    }

    .PPColumn1Content {
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
        margin-right: 0px;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(209, 209, 209, 0.5);

        display: flex;
        justify-content: center;
    }
    
    .PPColumn2Content {
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
        margin-top: 20px;
        margin-left: 0px;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(209, 209, 209, 0.5);

        display: flex;
        justify-content: center;
    }

    .PPColumn2TextBox {
        font-size: 12px !important;
    }

    .PPColumn2TextBox h2 {
        text-align: center;
    }

    .PPColumn2TextBox a {
        margin-bottom: 10px;
        text-decoration: none;
        text-align: center !important;
        color: purple;
        font-size: 13px;
    }

    .PPImageWrapper {
        width: 100%;
        margin-top: 20px;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(209, 209, 209, 0.5);

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .PPLinks {
        width: 100%;
    
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .PPLinks a {
        margin-bottom: 15px;
        text-decoration: none;
        color: purple;
        font-size: 14px;
    }

    .PPImageContainer {
        width: calc(100% - 40px);
        
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .PPImageContainer img {
        width: calc(100% - 40px);
        height: auto;
        border: 1px solid rgb(225, 225, 225);
    
        margin-bottom: 40px;
    }

    .PPTitle h3 {
        margin-top: 30px;
        font-size: 24px;
    }
    
    .PPTitle p {
        margin-top: -20px;
        margin-bottom: 20px;
        color: rgb(175, 175, 175);
        font-size: 13px;
        font-weight: 400;
    }
    
    .DirectionBar {
        width: 220px;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
    }
    
    .DirectionBar a {
        text-decoration: none;
        color: black;
        font-size: 12px;
    }
}

@media only screen and (max-width: 460px) {
    .PPWrapper {
        width: 100%;
    
        display: flex;
        flex-direction: column;
        align-items: center;
    
        animation: fade 0.6s ease-in;
    }
    
    .PPTitle {
        width: 100%;
        margin-top: 60px;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(209, 209, 209, 0.5);
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .PPContentWrapper {
        width: 100%;
        margin-top: 20px;
    
        display: flex;
        flex-wrap: wrap;
    }

    .PPColumn1 {
        width: 100%;
    
        display: flex;
        justify-content: flex-end;
    }

    .PPColumn2 {
        width: 100%;
    
        display: flex;
        justify-content: flex-start;
    }

    .PPColumn1Content {
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
        margin-right: 0px;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(209, 209, 209, 0.5);

        display: flex;
        justify-content: center;
    }

    .PPColumn1TextBox {
        font-size: 4vw !important;
    }
    
    .PPColumn2Content {
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
        margin-top: 20px;
        margin-left: 0px;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(209, 209, 209, 0.5);

        display: flex;
        justify-content: center;
    }

    .PPColumn2TextBox {
        font-size: 3vw !important;
    }

    .PPColumn2TextBox h2 {
        text-align: center;
        font-size: 5vw !important;
    }

    .PPColumn2TextBox a {
        margin-bottom: 10px;
        text-decoration: none;
        text-align: center !important;
        color: purple;
        font-size: 3.5vw !important;
    }

    .PPImageWrapper {
        width: 100%;
        margin-top: 20px;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(209, 209, 209, 0.5);

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .PPLinks {
        width: 100%;
    
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .PPLinks a {
        margin-bottom: 15px;
        text-decoration: none;
        color: purple;
        font-size: 3.5vw !important;
    }

    .PPImageContainer {
        width: calc(100% - 40px);
        
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .PPImageContainer img {
        width: calc(100% - 40px);
        height: auto;
        border: 1px solid rgb(225, 225, 225);
    
        margin-bottom: 40px;
    }

    .PPTitle h3 {
        margin-top: 30px;
        font-size: 5vw;
    }
    
    .PPTitle p {
        margin-top: -12px;
        margin-bottom: 20px;
        color: rgb(175, 175, 175);
        font-size: 3.25vw;
        font-weight: 400;
    }
    
    .DirectionBar {
        width: 170px;
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
    }
    
    .DirectionBar a {
        text-decoration: none;
        color: black;
        font-size: 2.5vw;
    }
}
.bar1 {
    width: 20px;
    height: 82px;
    background-color: rgb(255, 191, 191);
}

.bar2 {
    width: 20px;
    height: 68px;
    background-color: rgb(255, 204, 157);
}

.bar3 {
    width: 20px;
    height: 68px;
    background-color: rgb(182, 195, 255);
}

.bar4 {
    width: 20px;
    height: 68px;
    background-color: rgb(176, 143, 255);
}

.circle1 {
    position: absolute;
    width: 40px;
    height: 40px;
    margin-top: -3px;
    margin-left: -10px;
    border-radius: 100%;
    background-color: rgb(255, 110, 110);

    display: flex;
    justify-content: center;
    align-items: center;
}

.circle1:before {
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #f8f8f8;
}

.circle2 {
    position: absolute;
    width: 40px;
    height: 40px;
    margin-top: -3px;
    margin-left: -10px;
    border-radius: 100%;
    background-color: rgb(255, 186, 96);

    display: flex;
    justify-content: center;
    align-items: center;
}

.circle2:before {
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #f8f8f8;
}

.circle3 {
    position: absolute;
    width: 40px;
    height: 40px;
    margin-top: -3px;
    margin-left: -10px;
    border-radius: 100%;
    background-color: rgb(115, 152, 255);

    display: flex;
    justify-content: center;
    align-items: center;
}

.circle3:before {
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #f8f8f8;
}

.circle4 {
    position: absolute;
    width: 40px;
    height: 40px;
    margin-top: -3px;
    margin-left: -10px;
    border-radius: 100%;
    background-color: rgb(143, 95, 255);

    display: flex;
    justify-content: center;
    align-items: center;
}

.circle4:before {
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #f8f8f8;
}

#exp1 {
    color: rgb(255, 110, 110);
}

#exp2 {
    color: rgb(255, 186, 96);
}

#exp3 {
    color: rgb(115, 152, 255);
}

#exp4 {
    color: rgb(143, 95, 255);
}

@media only screen and (min-width: 760px) {
    .ExperienceWrapper {
        width: 100%;
    
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .ExperienceWrapper h2 {
        font-size: 28px;
        font-weight: 900;
    }

    .expTextBox {
        position: absolute;
        margin-left: 50px;
    }
    
    .expTextBoxL {
        position: absolute;
        margin-left: -230px;
    }
    
    .expTextBoxF {
        position: absolute;
        margin-left: -102px;
    }
    
    .expYear {
        margin-top: -56px;
        margin-bottom: -14px;
        font-size: 36px;
    
        font-family: "Khand";
    }
    
    .expYearL {
        margin-top: -56px;
        margin-bottom: -14px;
        font-size: 36px;
        text-align: right;
    
        font-family: "Khand";
    }

    .expJob {
        font-size: 14px;
        line-height: 7px;
        margin-bottom: 48px;
    }
    
    .expJobL {
        font-size: 14px;
        line-height: 7px;
        margin-bottom: 28px;
        text-align: right;
    }
}

@media only screen and (max-width: 759.9px) {
    .ExperienceWrapper {
        margin-left: 60px;
    
        display: flex;
        flex-direction: column;
    }

    .ExperienceWrapper h2 {
        font-size: 24px;
        font-weight: 900;
    }

    .expTextBox {
        position: absolute;
        margin-left: 50px;
    }
    
    .expTextBoxL {
        position: absolute;
        margin-left: 50px;
    }
    
    .expTextBoxF {
        position: absolute;
        margin-left: 50px;
    }
    
    .expYear {
        margin-top: -56px;
        margin-bottom: -14px;
        font-size: 36px;
    
        font-family: "Khand";
    }
    
    .expYearL {
        margin-top: -56px;
        margin-bottom: -14px;
        font-size: 36px;
        text-align: left;
    
        font-family: "Khand";
    }

    .expJob {
        font-size: 14px;
        line-height: 7px;
        margin-bottom: 48px;
    }
    
    .expJobL {
        font-size: 14px;
        line-height: 7px;
        margin-bottom: 28px;
        text-align: left;
    }
}

@media only screen and (max-width: 460px) {
    .ExperienceWrapper {
        margin-left: 32px;
    
        display: flex;
        flex-direction: column;
    }

    .ExperienceWrapper h2 {
        margin-top: 88px;
        font-size: 6vw;
        font-weight: 900;
        margin-bottom: 24px;
        margin-left: -32px;
        text-align: center;
    }

    .expTextBox {
        position: absolute;
        margin-left: 50px;
    }
    
    .expTextBoxL {
        position: absolute;
        margin-left: 50px;
    }
    
    .expTextBoxF {
        position: absolute;
        margin-left: 50px;
    }
    
    .expYear {
        margin-top: -56px;
        margin-bottom: -14px;
        font-size: 36px;
    
        font-family: "Khand";
    }
    
    .expYearL {
        margin-top: -56px;
        margin-bottom: -14px;
        font-size: 36px;
        text-align: left;
    
        font-family: "Khand";
    }

    .expJob {
        font-size: 3.5vw;
        line-height: 7px;
        margin-bottom: 48px;
    }
    
    .expJobL {
        font-size: 3.5vw;
        line-height: 7px;
        margin-bottom: 28px;
        text-align: left;
    }
}
html, body {
  background-color: #f8f8f8;
}

.HomeWrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  position: absolute;
  z-index: 2;
}

.HomeButton:hover {
  background-color:rgb(255, 238, 144);
  transition: linear 0.15s;
}

.PortfolioWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 1024px) {
  .HomeHeader {
    margin-left: 80px;
    width: calc(100% - 160px);
    height: 160px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .HomeHeader2 {
    width: calc(100% - 420px);
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .HomeTitle {
    width: 450px;
    color: white;
    font-size: 60px;
    font-family: 'Overpass';
    font-weight: 700;
    /* text-shadow: -3px 3px 14px #7e7e7e; */
  }
  
  .HomeSubtitle {
    margin-top: -10px;
    color: white;
    font-size: 24px;
    line-height: 0.2;
    font-family: 'Overpass';
    font-weight: 300;
  }
  
  #HomeSubtitleSubtext {
    font-size: 20px;
    font-family: 'Overpass';
    font-weight: 100;
  }
  
  .HomeButton {
    width: 100px;
    height: 40px;
    background-color: white;
    color:black;
    border-radius: 100px;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: 'Overpass';
    font-weight: 400;
  }
  
  .HomeButton p {
    margin-top: 12px;
  }

  .FeaturedContainer {
    top: calc(100vh - 130px);
    margin-left: 80px;
    position: absolute;
  }
  
  .FeaturedText {
    color: white;
    font-family: 'Overpass';
    font-weight: 400;
    font-size: 20px;
  }
  
  #FeaturedWorkName {
    font-weight: 300;
    font-style: normal;
    margin-top: -8px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 1023.9px) {
  .HomeHeader {
    margin-left: 80px;
    display: flex;
    flex-direction: column;
  }
  
  .HomeHeader2 {
    display: flex;
    flex-direction: column;
  }
  
  .HomeTitle {
    color: white;
    font-size: 60px;
    font-family: 'Overpass';
    font-weight: 700;
    margin-top: -18px;
    margin-bottom: -70px;
  }
  
  .HomeSubtitle {
    color: white;
    font-size: 24px;
    line-height: 0;
    font-family: 'Overpass';
    font-weight: 300;
    margin-bottom: 10px;
  }
  
  #HomeSubtitleSubtext {
    font-size: 20px;
    font-family: 'Overpass';
    font-weight: 100;
  }
  
  .HomeButton {
    width: 100px;
    height: 40px;
    background-color: white;
    color:black;
    border-radius: 100px;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: 'Overpass';
    font-weight: 400;
    font-size: 16px;
  }
  
  .HomeButton p {
    margin-top: 12px;
  }

  .FeaturedContainer {
    top: calc(100vh - 130px);
    margin-left: 80px;
    position: absolute;
  }
  
  .FeaturedText {
    color: white;
    font-family: 'Overpass';
    font-weight: 400;
    font-size: 20px;
  }
  
  #FeaturedWorkName {
    font-weight: 300;
    font-style: normal;
    margin-top: -8px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 600px) {
  .HomeHeader {
    margin-left: 40px;
    display: flex;
    flex-direction: column;
  }
  
  .HomeHeader2 {
    display: flex;
    flex-direction: column;
  }
  
  .HomeTitle {
    color: white;
    font-size: 60px;
    font-family: 'Overpass';
    font-weight: 700;
    margin-top: -18px;
    margin-bottom: -70px;
  }
  
  .HomeSubtitle {
    color: white;
    font-size: 24px;
    line-height: 0;
    font-family: 'Overpass';
    font-weight: 300;
    margin-bottom: 10px;
  }
  
  #HomeSubtitleSubtext {
    font-size: 20px;
    font-family: 'Overpass';
    font-weight: 100;
  }
  
  .HomeButton {
    width: 100px;
    height: 40px;
    background-color: white;
    color:black;
    border-radius: 100px;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: 'Overpass';
    font-weight: 400;
    font-size: 16px;
  }
  
  .HomeButton p {
    margin-top: 12px;
  }

  .FeaturedContainer {
    top: calc(100vh - 130px);
    margin-left: 40px;
    position: absolute;
  }
  
  .FeaturedText {
    color: white;
    font-family: 'Overpass';
    font-weight: 400;
    font-size: 20px;
  }
  
  #FeaturedWorkName {
    font-weight: 300;
    font-style: normal;
    margin-top: -8px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 490px) {
  .HomeHeader {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .HomeHeader2 {
    display: flex;
    flex-direction: column;
  }
  
  .HomeTitle {
    color: white;
    font-size: 12vw;
    font-family: 'Overpass';
    font-weight: 700;
    margin-top: -18px;
    margin-bottom: -50px;
  }
  
  .HomeSubtitle {
    color: white;
    font-size: 5vw;
    line-height: 0.4;
    font-family: 'Overpass';
    font-weight: 300;
    margin-bottom: 14px;
  }
  
  #HomeSubtitleSubtext {
    font-size: 4vw;
    font-family: 'Overpass';
    font-weight: 100;
  }
  
  .HomeButton {
    width: 100px;
    height: 40px;
    background-color: white;
    color:black;
    border-radius: 100px;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: 'Overpass';
    font-weight: 400;
    font-size: 16px;
  }
  
  .HomeButton p {
    margin-top: 12px;
  }

  .FeaturedContainer {
    top: calc(100vh - 110px);
    margin-left: 20px;
    position: absolute;
  }
  
  .FeaturedText {
    color: white;
    font-family: 'Overpass';
    font-weight: 400;
    font-size: 5vw;
  }
  
  #FeaturedWorkName {
    font-weight: 300;
    font-style: normal;
    margin-top: -8px;
    font-size: 4vw;
  }
}





.SlideContainer {
  width: 100%;
  background-color: black;
  position: absolute;
  z-index: 1;
}

.SlideImage {
  width: 100%;
  height: 100vh;
  filter: opacity(0.35);
}

.SlideImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}